<script>

export default {
	components: {
		Navbar: () => import('@/router/components/Navbar'),
		Footer: () => import('@/router/components/Footer'),
	},
  data() {
    return {

    };
  },
  created() {
    window.addEventListener("scroll", this.windowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  mounted() {

  },
  methods: {
    /**
     * Window scroll method
     */
    windowScroll() {
      const navbar = document.getElementById("navbar");
      if (
        document.body.scrollTop >= 50 ||
        document.documentElement.scrollTop >= 50
      ) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    },
    /**
     * Toggle menu
     */
    toggleMenu() {
      document.getElementById("topnav-menu-content").classList.toggle("show");
    }
  }
};
</script>

<template>
  <div>
    <Navbar :isHomepage="false"/>
    <div>
      <section class="section pt-4 mt-4 bg-white">
        <div class="mt-5 mb-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <h4>Privacy policy</h4>
                </div>
              </div>
            </div>
            <div>
	            <div>
		            Last updated April 18, 2023
	            </div>
              <p>We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the CFTools Software GmbH. The use of the Internet pages of the CFTools Software GmbH is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.</p>

              <p>The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the CFTools Software GmbH. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.</p>

              <p>As the controller, the CFTools Software GmbH has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone. </p>

              <h4>1. Definitions</h4>
              <p>The data protection declaration of the CFTools Software GmbH is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.</p>

              <p>In this data protection declaration, we use, inter alia, the following terms:</p>

              <ul style="list-style: none">
                <li><h4>a) Personal data</h4>
                  <p>Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                </li>
                <li><h4>b) Data subject</h4>
                  <p>Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.</p>
                </li>
                <li><h4>c) Processing</h4>
                  <p>Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </p>
                </li>
                <li><h4>d) Restriction of processing</h4>
                  <p>Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future. </p>
                </li>
                <li><h4>e) Profiling</h4>
                  <p>Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements. </p>
                </li>
                <li><h4>f) Pseudonymisation</h4>
                  <p>Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person. </p>
                </li>
                <li><h4>g) Controller or controller responsible for the processing</h4>
                  <p>Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law. </p>
                </li>
                <li><h4>h) Processor</h4>
                  <p>Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller. </p>
                </li>
                <li><h4>i) Recipient</h4>
                  <p>Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing. </p>
                </li>
                <li><h4>j) Third party</h4>
                  <p>Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</p>
                </li>
                <li><h4>k) Consent</h4>
                  <p>Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her. </p>
                </li>
              </ul>

              <h4>2. Name and Address of the controller</h4>
              <p>Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is:

              </p>


              <div>
                <div>
                  CFTools Software GmbH
                </div>
                <div>
                  Kiem-Pauli-Stra&szlig;e 8
                </div>
                <div>
                  Landshut 84036
                </div>
                <div>
                  Germany
                </div>
              </div>
              <div class="mt-2">
                <div>
                  Tel. +Deutschland \ 8 7 1 2 0 5 4 9 0 4 0
                </div>
                <div>
                  We do not offer any form of phone support.
                </div>
                <div>
                  E-Mail:
                  <span style="margin-right: 0.5px;">
                        legal
                      </span>
                  <label style="margin-left: 0.38px; margin-right: 0.5px;">
                    @
                  </label>
                  <span style="margin-left: 1.38px;">
                        cftools.software
                      </span>
                </div>
              </div>

              <h4>3. Cookies</h4>
              <p>The Internet pages of the CFTools Software GmbH use cookies. Cookies are text files that are stored in a computer system via an Internet browser.</p>

              <p>Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the dats subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.</p>

              <p>Through the use of cookies, the CFTools Software GmbH can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.</p>

              <p>By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.</p>

              <p>The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.</p>

              <h4>4. Collection of general data and information</h4>
              <p>The website of the CFTools Software GmbH collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data and information that may be used in the event of attacks on our information technology systems.</p>

              <p>When using these general data and information, the CFTools Software GmbH does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, the CFTools Software GmbH analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.</p>

              <h4>5. Registration on our website</h4>
              <p>The data subject has the possibility to register on the website of the controller with the indication of personal data. Which personal data are transmitted to the controller is determined by the respective input mask used for the registration. The personal data entered by the data subject are collected and stored exclusively for internal use by the controller, and for his own purposes. The controller may request transfer to one or more processors (e.g. a parcel service) that also uses personal data for an internal purpose which is attributable to the controller.</p>

              <p>By registering on the website of the controller, the IP address—assigned by the Internet service provider (ISP) and used by the data subject—date, and time of the registration are also stored. The storage of this data takes place against the background that this is the only way to prevent the misuse of our services, and, if necessary, to make it possible to investigate committed offenses. Insofar, the storage of this data is necessary to secure the controller. This data is not passed on to third parties unless there is a statutory obligation to pass on the data, or if the transfer serves the aim of criminal prosecution.

              </p>

              <p>The registration of the data subject, with the voluntary indication of personal data, is intended to enable the controller to offer the data subject contents or services that may only be offered to registered users due to the nature of the matter in question. Registered persons are free to change the personal data specified during the registration at any time, or to have them completely deleted from the data stock of the controller.</p>

              <p>The data controller shall, at any time, provide information upon request to each data subject as to what personal data are stored about the data subject. In addition, the data controller shall correct or erase personal data at the request or indication of the data subject, insofar as there are no statutory storage obligations. The entirety of the controller’s employees are available to the data subject in this respect as contact persons.</p>

              <h4>6. Subscription to our newsletters</h4>
              <p>On the website of the CFTools Software GmbH, users are given the opportunity to subscribe to our enterprise's newsletter. The input mask used for this purpose determines what personal data are transmitted, as well as when the newsletter is ordered from the controller.</p>

              <p>The CFTools Software GmbH informs its customers and business partners regularly by means of a newsletter about enterprise offers. The enterprise's newsletter may only be received by the data subject if (1) the data subject has a valid e-mail address and (2) the data subject registers for the newsletter shipping. A confirmation e-mail will be sent to the e-mail address registered by a data subject for the first time for newsletter shipping, for legal reasons, in the double opt-in procedure. This confirmation e-mail is used to prove whether the owner of the e-mail address as the data subject is authorized to receive the newsletter.

              </p>

              <p>During the registration for the newsletter, we also store the IP address of the computer system assigned by the Internet service provider (ISP) and used by the data subject at the time of the registration, as well as the date and time of the registration. The collection of this data is necessary in order to understand the (possible) misuse of the e-mail address of a data subject at a later date, and it therefore serves the aim of the legal protection of the controller.</p>

              <p>The personal data collected as part of a registration for the newsletter will only be used to send our newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as this is necessary for the operation of the newsletter service or a registration in question, as this could be the case in the event of modifications to the newsletter offer, or in the event of a change in technical circumstances. There will be no transfer of personal data collected by the newsletter service to third parties. The subscription to our newsletter may be terminated by the data subject at any time. The consent to the storage of personal data, which the data subject has given for shipping the newsletter, may be revoked at any time. For the purpose of revocation of consent, a corresponding link is found in each newsletter. It is also possible to unsubscribe from the newsletter at any time directly on the website of the controller, or to communicate this to the controller in a different way.</p>

              <h4>7. Newsletter-Tracking</h4>
              <p>The newsletter of the CFTools Software GmbH contains so-called tracking pixels. A tracking pixel is a miniature graphic embedded in such e-mails, which are sent in HTML format to enable log file recording and analysis. This allows a statistical analysis of the success or failure of online marketing campaigns. Based on the embedded tracking pixel, the CFTools Software GmbH may see if and when an e-mail was opened by a data subject, and which links in the e-mail were called up by data subjects.</p>

              <p>Such personal data collected in the tracking pixels contained in the newsletters are stored and analyzed by the controller in order to optimize the shipping of the newsletter, as well as to adapt the content of future newsletters even better to the interests of the data subject. These personal data will not be passed on to third parties. Data subjects are at any time entitled to revoke the respective separate declaration of consent issued by means of the double-opt-in procedure. After a revocation, these personal data will be deleted by the controller. The CFTools Software GmbH automatically regards a withdrawal from the receipt of the newsletter as a revocation.</p>

              <h4>8. Contact possibility via the website </h4>
              <p>The website of the CFTools Software GmbH contains information that enables a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or via a contact form, the personal data transmitted by the data subject are automatically stored. Such personal data transmitted on a voluntary basis by a data subject to the data controller are stored for the purpose of processing or contacting the data subject. There is no transfer of this personal data to third parties.</p>

              <h4>9. Subscription to comments in the blog on the website</h4>
              <p>
                The comments made in the blog of the CFTools Software GmbH may be subscribed to by third parties. In particular, there is the possibility that a commenter subscribes to the comments following his comments on a particular blog post.
              </p>

              <p>If a data subject decides to subscribe to the option, the controller will send an automatic confirmation e-mail to check the double opt-in procedure as to whether the owner of the specified e-mail address decided in favor of this option. The option to subscribe to comments may be terminated at any time.</p>

              <h4>10. Routine erasure and blocking of personal data</h4>
              <p>The data controller shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller is subject to.</p>

              <p>If the storage purpose is not applicable, or if a storage period prescribed by the European legislator or another competent legislator expires, the personal data are routinely blocked or erased in accordance with legal requirements.</p>

              <h4>11. Rights of the data subject</h4>
              <ul style="list-style: none;">
                <li><h4>a) Right of confirmation</h4>
                  <p>Each data subject shall have the right granted by the European legislator to obtain from the controller the confirmation as to whether or not personal data concerning him or her are being processed. If a data subject wishes to avail himself of this right of confirmation, he or she may, at any time, contact any employee of the controller.</p>
                </li>
                <li><h4>b) Right of access</h4>
                  <p>Each data subject shall have the right granted by the European legislator to obtain from the controller free information about his or her personal data stored at any time and a copy of this information. Furthermore, the European directives and regulations grant the data subject access to the following information:</p>

                  <ul style="list-style: none;">
                    <li>the purposes of the processing;</li>
                    <li>the categories of personal data concerned;</li>
                    <li>the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations;</li>
                    <li>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</li>
                    <li>the existence of the right to request from the controller rectification or erasure of personal data, or restriction of processing of personal data concerning the data subject, or to object to such processing;</li>
                    <li>the existence of the right to lodge a complaint with a supervisory authority;</li>
                    <li>where the personal data are not collected from the data subject, any available information as to their source;</li>
                    <li>the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and envisaged consequences of such processing for the data subject.</li>

                  </ul>
                  <p>Furthermore, the data subject shall have a right to obtain information as to whether personal data are transferred to a third country or to an international organisation. Where this is the case, the data subject shall have the right to be informed of the appropriate safeguards relating to the transfer.</p>

                  <p>If a data subject wishes to avail himself of this right of access, he or she may, at any time, contact any employee of the controller.</p>
                </li>
                <li><h4>c) Right to rectification </h4>
                  <p>Each data subject shall have the right granted by the European legislator to obtain from the controller without undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the purposes of the processing, the data subject shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</p>

                  <p>If a data subject wishes to exercise this right to rectification, he or she may, at any time, contact any employee of the controller.</p></li>
                <li>
                  <h4>d) Right to erasure (Right to be forgotten) </h4>
                  <p>Each data subject shall have the right granted by the European legislator to obtain from the controller the erasure of personal data concerning him or her without undue delay, and the controller shall have the obligation to erase personal data without undue delay where one of the following grounds applies, as long as the processing is not necessary: </p>

                  <ul style="list-style: none;">
                    <li>The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.</li>
                    <li>The data subject withdraws consent to which the processing is based according to point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the processing.</li>
                    <li>The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) of the GDPR. </li>
                    <li>The personal data have been unlawfully processed.</li>
                    <li>The personal data must be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject.</li>
                    <li>The personal data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.</li>

                  </ul>
                  <p>If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the CFTools Software GmbH, he or she may, at any time, contact any employee of the controller. An employee of CFTools Software GmbH shall promptly ensure that the erasure request is complied with immediately.</p>

                  <p>Where the controller has made personal data public and is obliged pursuant to Article 17(1) to erase the personal data, the controller, taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform other controllers processing the personal data that the data subject has requested erasure by such controllers of any links to, or copy or replication of, those personal data, as far as processing is not required. An employees of the CFTools Software GmbH will arrange the necessary measures in individual cases.</p>
                </li>
                <li><h4>e) Right of restriction of processing</h4>
                  <p>Each data subject shall have the right granted by the European legislator to obtain from the controller restriction of processing where one of the following applies:</p>

                  <ul style="list-style: none;">
                    <li>The accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data. </li>
                    <li>The processing is unlawful and the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.</li>
                    <li>The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims.</li>
                    <li>The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.</li>

                  </ul>
                  <p>If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of the processing of personal data stored by the CFTools Software GmbH, he or she may at any time contact any employee of the controller. The employee of the CFTools Software GmbH will arrange the restriction of the processing. </p>
                </li>
                <li><h4>f) Right to data portability</h4>
                  <p>Each data subject shall have the right granted by the European legislator, to receive the personal data concerning him or her, which was provided to a controller, in a structured, commonly used and machine-readable format. He or she shall have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided, as long as the processing is based on consent pursuant to point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the processing is not necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.</p>

                  <p>Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to have personal data transmitted directly from one controller to another, where technically feasible and when doing so does not adversely affect the rights and freedoms of others.</p>

                  <p>In order to assert the right to data portability, the data subject may at any time contact any employee of the CFTools Software GmbH.</p>

                </li>
                <li>
                  <h4>g) Right to object</h4>
                  <p>Each data subject shall have the right granted by the European legislator to object, on grounds relating to his or her particular situation, at any time, to processing of personal data concerning him or her, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.</p>

                  <p>The CFTools Software GmbH shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defence of legal claims.</p>

                  <p>If the CFTools Software GmbH processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data concerning him or her for such marketing. This applies to profiling to the extent that it is related to such direct marketing. If the data subject objects to the CFTools Software GmbH to the processing for direct marketing purposes, the CFTools Software GmbH will no longer process the personal data for these purposes.</p>

                  <p>In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her by the CFTools Software GmbH for scientific or historical research purposes, or for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</p>

                  <p>In order to exercise the right to object, the data subject may contact any employee of the CFTools Software GmbH. In addition, the data subject is free in the context of the use of information society services, and notwithstanding Directive 2002/58/EC, to use his or her right to object by automated means using technical specifications.</p>
                </li>
                <li><h4>h) Automated individual decision-making, including profiling</h4>
                  <p>Each data subject shall have the right granted by the European legislator not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her, or similarly significantly affects him or her, as long as the decision (1) is not is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) is not authorised by Union or Member State law to which the controller is subject and which also lays down suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, or (3) is not based on the data subject's explicit consent.</p>

                  <p>If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) it is based on the data subject's explicit consent, the CFTools Software GmbH shall implement suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, at least the right to obtain human intervention on the part of the controller, to express his or her point of view and contest the decision.</p>

                  <p>If the data subject wishes to exercise the rights concerning automated individual decision-making, he or she may, at any time, contact any employee of the CFTools Software GmbH.</p>

                </li>
                <li><h4>i) Right to withdraw data protection consent </h4>
                  <p>Each data subject shall have the right granted by the European legislator to withdraw his or her consent to processing of his or her personal data at any time. </p>

                  <p>If the data subject wishes to exercise the right to withdraw the consent, he or she may, at any time, contact any employee of the CFTools Software GmbH.</p>

                </li>
              </ul>
              <h4>12. Data protection for applications and the application procedures</h4>
              <p>The data controller shall collect and process the personal data of applicants for the purpose of the processing of the application procedure. The processing may also be carried out electronically. This is the case, in particular, if an applicant submits corresponding application documents by e-mail or by means of a web form on the website to the controller. If the data controller concludes an employment contract with an applicant, the submitted data will be stored for the purpose of processing the employment relationship in compliance with legal requirements. If no employment contract is concluded with the applicant by the controller, the application documents shall be automatically erased two months after notification of the refusal decision, provided that no other legitimate interests of the controller are opposed to the erasure. Other legitimate interest in this relation is, e.g. a burden of proof in a procedure under the General Equal Treatment Act (AGG).</p>

              <h4>13. Data protection provisions about the application and use of Google AdSense</h4>
              <p>On this website, the controller has integrated Google AdSense. Google AdSense is an online service which allows the placement of advertising on third-party sites. Google AdSense is based on an algorithm that selects advertisements displayed on third-party sites to match with the content of the respective third-party site. Google AdSense allows an interest-based targeting of the Internet user, which is implemented by means of generating individual user profiles. </p>

              <p>The operating company of Google's AdSense component is Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.</p>

              <p>The purpose of Google's AdSense component is the integration of advertisements on our website. Google AdSense places a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, Alphabet Inc. is enabled to analyze the use of our website. With each call-up to one of the individual pages of this Internet site, which is operated by the controller and into which a Google AdSense component is integrated, the Internet browser on the information technology system of the data subject will automatically submit data through the Google AdSense component for the purpose of online advertising and the settlement of commissions to Alphabet Inc. During the course of this technical procedure, the enterprise Alphabet Inc. gains knowledge of personal data, such as the IP address of the data subject, which serves Alphabet Inc., inter alia, to understand the origin of visitors and clicks and subsequently create commission settlements.

              </p>

              <p>The data subject may, as stated above, prevent the setting of cookies through our website at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the Internet browser used would also prevent Alphabet Inc. from setting a cookie on the information technology system of the data subject. Additionally, cookies already in use by Alphabet Inc. may be deleted at any time via a web browser or other software programs.</p>

              <p>Furthermore, Google AdSense also uses so-called tracking pixels. A tracking pixel is a miniature graphic that is embedded in web pages to enable a log file recording and a log file analysis through which a statistical analysis may be performed. Based on the embedded tracking pixels, Alphabet Inc. is able to determine if and when a website was opened by a data subject, and which links were clicked on by the data subject. Tracking pixels serve, inter alia, to analyze the flow of visitors on a website.</p>

              <p>Through Google AdSense, personal data and information—which also includes the IP address, and is necessary for the collection and accounting of the displayed advertisements—is transmitted to Alphabet Inc. in the United States of America. These personal data will be stored and processed in the United States of America. The Alphabet Inc. may disclose the collected personal data through this technical procedure to third parties.</p>

              <p>Google AdSense is further explained under the following link https://www.google.com/intl/en/adsense/start/.</p>

              <h4>14. Data protection provisions about the application and use of Google Analytics (with anonymization function)</h4>
              <p>On this website, the controller has integrated the component of Google Analytics (with the anonymizer function). Google Analytics is a web analytics service. Web analytics is the collection, gathering, and analysis of data about the behavior of visitors to websites. A web analysis service collects, inter alia, data about the website from which a person has come (the so-called referrer), which sub-pages were visited, or how often and for what duration a sub-page was viewed. Web analytics are mainly used for the optimization of a website and in order to carry out a cost-benefit analysis of Internet advertising.</p>

              <p>The operator of the Google Analytics component is Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.</p>

              <p>For the web analytics through Google Analytics the controller uses the application "_gat. _anonymizeIp". By means of this application the IP address of the Internet connection of the data subject is abridged by Google and anonymised when accessing our websites from a Member State of the European Union or another Contracting State to the Agreement on the European Economic Area.</p>

              <p>The purpose of the Google Analytics component is to analyze the traffic on our website. Google uses the collected data and information, inter alia, to evaluate the use of our website and to provide online reports, which show the activities on our websites, and to provide other services concerning the use of our Internet site for us.</p>

              <p>Google Analytics places a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, Google is enabled to analyze the use of our website. With each call-up to one of the individual pages of this Internet site, which is operated by the controller and into which a Google Analytics component was integrated, the Internet browser on the information technology system of the data subject will automatically submit data through the Google Analytics component for the purpose of online advertising and the settlement of commissions to Google. During the course of this technical procedure, the enterprise Google gains knowledge of personal information, such as the IP address of the data subject, which serves Google, inter alia, to understand the origin of visitors and clicks, and subsequently create commission settlements.</p>

              <p>The cookie is used to store personal information, such as the access time, the location from which the access was made, and the frequency of visits of our website by the data subject. With each visit to our Internet site, such personal data, including the IP address of the Internet access used by the data subject, will be transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass these personal data collected through the technical procedure to third parties.

              </p>

              <p>The data subject may, as stated above, prevent the setting of cookies through our website at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the Internet browser used would also prevent Google Analytics from setting a cookie on the information technology system of the data subject. In addition, cookies already in use by Google Analytics may be deleted at any time via a web browser or other software programs.</p>

              <p>In addition, the data subject has the possibility of objecting to a collection of data that are generated by Google Analytics, which is related to the use of this website, as well as the processing of this data by Google and the chance to preclude any such. For this purpose, the data subject must download a browser add-on under the link https://tools.google.com/dlpage/gaoptout and install it. This browser add-on tells Google Analytics through a JavaScript, that any data and information about the visits of Internet pages may not be transmitted to Google Analytics. The installation of the browser add-ons is considered an objection by Google. If the information technology system of the data subject is later deleted, formatted, or newly installed, then the data subject must reinstall the browser add-ons to disable Google Analytics. If the browser add-on was uninstalled by the data subject or any other person who is attributable to their sphere of competence, or is disabled, it is possible to execute the reinstallation or reactivation of the browser add-ons.</p>

              <p>Further information and the applicable data protection provisions of Google may be retrieved under https://www.google.com/intl/en/policies/privacy/ and under http://www.google.com/analytics/terms/us.html. Google Analytics is further explained under the following Link https://www.google.com/analytics/.</p>

              <h4>15. Data protection provisions about the application and use of Google-AdWords</h4>

              <p>On this website, the controller has integrated Google AdWords. Google AdWords is a service for Internet advertising that allows the advertiser to place ads in Google search engine results and the Google advertising network. Google AdWords allows an advertiser to pre-define specific keywords with the help of which an ad on Google's search results only then displayed, when the user utilizes the search engine to retrieve a keyword-relevant search result. In the Google Advertising Network, the ads are distributed on relevant web pages using an automatic algorithm, taking into account the previously defined keywords.</p>

              <p>The operating company of Google AdWords is Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.</p>

              <p>The purpose of Google AdWords is the promotion of our website by the inclusion of relevant advertising on the websites of third parties and in the search engine results of the search engine Google and an insertion of third-party advertising on our website.</p>

              <p>If a data subject reaches our website via a Google ad, a conversion cookie is filed on the information technology system of the data subject through Google. The definition of cookies is explained above. A conversion cookie loses its validity after 30 days and is not used to identify the data subject. If the cookie has not expired, the conversion cookie is used to check whether certain sub-pages, e.g, the shopping cart from an online shop system, were called up on our website. Through the conversion cookie, both Google and the controller can understand whether a person who reached an AdWords ad on our website generated sales, that is, executed or canceled a sale of goods.</p>

              <p>The data and information collected through the use of the conversion cookie is used by Google to create visit statistics for our website. These visit statistics are used in order to determine the total number of users who have been served through AdWords ads to ascertain the success or failure of each AdWords ad and to optimize our AdWords ads in the future. Neither our company nor other Google AdWords advertisers receive information from Google that could identify the data subject.</p>

              <p>The conversion cookie stores personal information, e.g. the Internet pages visited by the data subject. Each time we visit our Internet pages, personal data, including the IP address of the Internet access used by the data subject, is transmitted to Google in the United States of America. These personal data are stored by Google in the United States of America. Google may pass these personal data collected through the technical procedure to third parties.</p>

              <p>The data subject may, at any time, prevent the setting of cookies by our website, as stated above, by means of a corresponding setting of the Internet browser used and thus permanently deny the setting of cookies. Such a setting of the Internet browser used would also prevent Google from placing a conversion cookie on the information technology system of the data subject. In addition, a cookie set by Google AdWords may be deleted at any time via the Internet browser or other software programs.</p>

              <p>The data subject has a possibility of objecting to the interest based advertisement of Google. Therefore, the data subject must access from each of the browsers in use the link www.google.de/settings/ads and set the desired settings.</p>

              <p>Further information and the applicable data protection provisions of Google may be retrieved under https://www.google.com/intl/en/policies/privacy/.</p>

              <h4>16a. Data protection provisions about the application and use of Matomo</h4>
              <p>On this website, the controller has integrated the Matomo component. Matomo is an open-source software tool for web analysis. Web analysis is the collection, gathering and evaluation of data on the behavior of visitors from Internet sites. A web analysis tool collects, inter alia, data on the website from which a data subject came to a website (so-called referrer), which pages of the website were accessed or how often and for which period of time a sub-page was viewed. A web analysis is mainly used for the optimization of a website and the cost-benefit analysis of Internet advertising.</p>

              <p>The software is operated on the server of the controller, the data protection-sensitive log files are stored exclusively on this server.</p>

              <p>The purpose of the Matomo component is the analysis of the visitor flows on our website. The controller uses the obtained data and information, inter alia, to evaluate the use of this website in order to compile online reports, which show the activities on our Internet pages.</p>

              <p>Matomo sets a cookie on the information technology system of the data subject. The definition of cookies is explained above. With the setting of the cookie, an analysis of the use of our website is enabled. With each call-up to one of the individual pages of this website, the Internet browser on the information technology system of the data subject is automatically through the Matomo component prompted to submit data for the purpose of online analysis to our server. During the course of this technical procedure, we obtain knowledge about personal information, such as the IP address of the data subject, which serves to understand the origin of visitors and clicks.</p>

              <p>The cookie is used to store personal information, such as the access time, the location from which access was made, and the frequency of visits to our website. With each visit of our Internet pages, these personal data, including the IP address of the Internet access used by the data subject, are transferred to our server. These personal data will be stored by us. We do not forward this personal data to third parties.</p>

              <p>The data subject may, as stated above, prevent the setting of cookies through our website at any time by means of a corresponding adjustment of the web browser used and thus permanently deny the setting of cookies. Such an adjustment to the used Internet browser would also prevent Matomo from setting a cookie on the information technology system of the data subject. In addition, cookies already in use by Matomo may be deleted at any time via a web browser or other software programs.</p>

              <p>In addition, the data subject has the possibility of objecting to a collection of data relating to a use of this Internet site that are generated by Matomo as well as the processing of these data by Matomo and the chance to preclude any such. For this, the data subject must set a "Do Not Track" option in the browser.</p>

              <p>With each setting of the opt-out cookie, however, there is the possibility that the websites of the controller are no longer fully usable for the data subject.</p>

              <p>Further information and the applicable data protection provisions of Matomo may be retrieved under https://matomo.org/privacy/.</p>

              <h4>17. Data protection provisions about the application and use of Twitter</h4>
              <p>On this website, the controller has integrated components of Twitter. Twitter is a multilingual, publicly-accessible microblogging service on which users may publish and spread so-called ‘tweets,’ e.g. short messages, which are limited to 280 characters. These short messages are available for everyone, including those who are not logged on to Twitter. The tweets are also displayed to so-called followers of the respective user. Followers are other Twitter users who follow a user's tweets. Furthermore, Twitter allows you to address a wide audience via hashtags, links or retweets.</p>

              <p>The operating company of Twitter is Twitter International Company, One Cumberland Place, Fenian Street Dublin 2, D02 AX07, Ireland.</p>

              <p>With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which a Twitter component (Twitter button) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to download a display of the corresponding Twitter component of Twitter. Further information about the Twitter buttons is available under https://about.twitter.com/de/resources/buttons. During the course of this technical procedure, Twitter gains knowledge of what specific sub-page of our website was visited by the data subject. The purpose of the integration of the Twitter component is a retransmission of the contents of this website to allow our users to introduce this web page to the digital world and increase our visitor numbers.</p>

              <p>If the data subject is logged in at the same time on Twitter, Twitter detects with every call-up to our website by the data subject and for the entire duration of their stay on our Internet site which specific sub-page of our Internet page was visited by the data subject. This information is collected through the Twitter component and associated with the respective Twitter account of the data subject. If the data subject clicks on one of the Twitter buttons integrated on our website, then Twitter assigns this information to the personal Twitter user account of the data subject and stores the personal data.</p>

              <p>Twitter receives information via the Twitter component that the data subject has visited our website, provided that the data subject is logged in on Twitter at the time of the call-up to our website. This occurs regardless of whether the person clicks on the Twitter component or not. If such a transmission of information to Twitter is not desirable for the data subject, then he or she may prevent this by logging off from their Twitter account before a call-up to our website is made.</p>

              <p>The applicable data protection provisions of Twitter may be accessed under https://twitter.com/privacy?lang=en.</p>

              <h4>18. Data protection provisions about the application and use of YouTube</h4>
              <p>On this website, the controller has integrated components of YouTube. YouTube is an Internet video portal that enables video publishers to set video clips and other users free of charge, which also provides free viewing, review and commenting on them. YouTube allows you to publish all kinds of videos, so you can access both full movies and TV broadcasts, as well as music videos, trailers, and videos made by users via the Internet portal.
              </p>

              <p>The operating company of YouTube is Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.</p>

              <p>With each call-up to one of the individual pages of this Internet site, which is operated by the controller and on which a YouTube component (YouTube video) was integrated, the Internet browser on the information technology system of the data subject is automatically prompted to download a display of the corresponding YouTube component. Further information about YouTube may be obtained under https://www.youtube.com/yt/about/en/. During the course of this technical procedure, YouTube and Google gain knowledge of what specific sub-page of our website was visited by the data subject.</p>

              <p>If the data subject is logged in on YouTube, YouTube recognizes with each call-up to a sub-page that contains a YouTube video, which specific sub-page of our Internet site was visited by the data subject. This information is collected by YouTube and Google and assigned to the respective YouTube account of the data subject.</p>

              <p>YouTube and Google will receive information through the YouTube component that the data subject has visited our website, if the data subject at the time of the call to our website is logged in on YouTube; this occurs regardless of whether the person clicks on a YouTube video or not. If such a transmission of this information to YouTube and Google is not desirable for the data subject, the delivery may be prevented if the data subject logs off from their own YouTube account before a call-up to our website is made.</p>

              <p>YouTube's data protection provisions, available at https://www.google.com/intl/en/policies/privacy/, provide information about the collection, processing and use of personal data by YouTube and Google.</p>

              <h4>19a. Payment Method: Data protection provisions about the use of PayPal as a payment processor</h4>
              <p>On this website, the controller has integrated components of PayPal. PayPal is an online payment service provider. Payments are processed via so-called PayPal accounts, which represent virtual private or business accounts. PayPal is also able to process virtual payments through credit cards when a user does not have a PayPal account. A PayPal account is managed via an e-mail address, which is why there are no classic account numbers. PayPal makes it possible to trigger online payments to third parties or to receive payments. PayPal also accepts trustee functions and offers buyer protection services.</p>

              <p>The European operating company of PayPal is PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal L-2449, Luxemburg.</p>

              <p>If the data subject chooses "PayPal" as the payment option in the online shop during the ordering process, we automatically transmit the data of the data subject to PayPal. By selecting this payment option, the data subject agrees to the transfer of personal data required for payment processing.</p>

              <p>The personal data transmitted to PayPal is usually first name, last name, address, email address, IP address, telephone number, mobile phone number, or other data necessary for payment processing. The processing of the purchase contract also requires such personal data, which are in connection with the respective order.</p>

              <p>The transmission of the data is aimed at payment processing and fraud prevention. The controller will transfer personal data to PayPal, in particular, if a legitimate interest in the transmission is given. The personal data exchanged between PayPal and the controller for the processing of the data will be transmitted by PayPal to economic credit agencies. This transmission is intended for identity and creditworthiness checks.</p>

              <p>PayPal will, if necessary, pass on personal data to affiliates and service providers or subcontractors to the extent that this is necessary to fulfill contractual obligations or for data to be processed in the order.</p>

              <p>The data subject has the possibility to revoke consent for the handling of personal data at any time from PayPal. A revocation shall not have any effect on personal data which must be processed, used or transmitted in accordance with (contractual) payment processing.</p>

              <p>The applicable data protection provisions of PayPal may be retrieved under https://www.paypal.com/us/webapps/mpp/ua/privacy-full.</p>

              <h4>19b. Payment Method: Data protection provisions about the use of Stripe as a payment processor</h4>
              <p>On this website, the controller has integrated components of Stripe. Stripe is an online payment service provider. Payments are processed via so-called Stripe accounts, which represent virtual private or business accounts. Stripe is also able to process virtual payments through credit cards, bank debits and other payment methods. Stripe makes it possible to trigger online payments to third parties or to receive payments.</p>

              <p>The European operating company of Stripe is Stripe Payments Europe, Ltd., 1 Grand Canal Street Lower, Grand Canal Dock, Dublin, Ireland.</p>

              <p>If the data subject chooses "Stripe " as the payment provider in the online shop during the ordering process, we automatically transmit the data of the data subject to Stripe. By selecting this payment option, the data subject agrees to the transfer of personal data required for payment processing.</p>

              <p>The personal data transmitted to Stripe is usually first name, last name, address, email address, IP address, telephone number, mobile phone number, or other data necessary for payment processing. The processing of the purchase contract also requires such personal data, which are in connection with the respective order.</p>

              <p>The transmission of the data is aimed at payment processing and fraud prevention. The controller will transfer personal data to Stripe, in particular, if a legitimate interest in the transmission is given. The personal data exchanged between Stripe and the controller for the processing of the data will be transmitted by Stripe to economic credit agencies. This transmission is intended for identity and creditworthiness checks.</p>

              <p>Stripe will, if necessary, pass on personal data to affiliates and service providers or subcontractors to the extent that this is necessary to fulfill contractual obligations or for data to be processed in the order.</p>

              <p>The data subject has the possibility to revoke consent for the handling of personal data at any time from Stripe. A revocation shall not have any effect on personal data which must be processed, used or transmitted in accordance with (contractual) payment processing.</p>

              <p>The applicable data protection provisions of Stripe may be retrieved under https://stripe.com/privacy.</p>
	            <h4>19c. Billing Address: Data protection provisions about the use of Google Cloud API services as a address validator</h4>
	            <p>On this website, the controlled has integrated components of Google Ireland Limited, Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland. Google Cloud provides API services to enable us to validate billing address and comply with tax regulations.</p>
	            <p>The personal data transmitted to Google Cloud is usually address and IP address, or other data necessary for address validation.</p>

	            <p>The transmission of the data is aimed at address validation and fraud prevention. The controller will transfer personal data to Google Cloud, in particular, if a legitimate interest in the transmission is given.</p>

	            <p>Google Cloud will, if necessary, pass on personal data to affiliates and service providers or subcontractors to the extent that this is necessary to fulfill contractual obligations or for data to be processed in the order.</p>
	            <p>The applicable data protection provisions of Google Cloud may be retrieved under https://cloud.google.com/terms/cloud-privacy-notice.</p>

              <h4>20. Legal basis for the processing </h4>
              <p>Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example, when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services. Is our company subject to a legal obligation by which processing of personal data is required, such as for the fulfillment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR.
                In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case, for example, if a visitor were injured in our company and his name, age, health insurance data or other vital information would have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6(1) lit. d GDPR.
                Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).
              </p>
              <h4>20b. End user processing clarifications</h4>
              <p>As part of our products and services, customers provide us with end user data and information for processing. The processing of this data is subject to this privacy policy and an underlying data processing agreement with the customer.</p>
              <p>End users consuming services provided by our customers that utilize our products or services are subject to the same processing standards and agreements outlined in this policy.</p>
              <p>Data provided by our customers about end users will be processed to operate and develop our products and services. The role as data controller and obligations coming thereof remains intact for our customers in regards to their end users.</p>

              <h4>21. The legitimate interests pursued by the controller or by a third party</h4>
              <p>Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to carry out our business in favor of the well-being of all our employees and the shareholders.</p>

              <p>You may find a complete list of these Privacy Policies and their links here:</p>
              <ul>
                <li><a href="https://discordapp.com/privacy">Discord</a></li>
                <li><a href="https://store.steampowered.com/privacy_agreement/">Steam</a></li>
                <li><a href="https://www.twitch.tv/p/legal/privacy-policy/">Twitch</a></li>
                <li><a href="https://proxycheck.io/privacy/">proxycheck</a></li>
                <li><a href="https://www.cloudflare.com/privacypolicy/">Cloudflare</a></li>
                <li><a href="https://www.mailgun.com/privacy-policy/">Mailgun</a></li>
                <li><a href="https://www.netcup.eu/kontakt/datenschutzerklaerung.php">Netcup</a></li>
                <li><a href="https://www.hetzner.com/legal/privacy-policy">Hetzner</a></li>
                <li><a href="https://www.ovh.com/world/support/privacy-policy.xml">OVH</a></li>
                <li><a href="https://hostkey.com/about-us/legal/privacy-statement/">Hostkey</a></li>
                <li><a href="https://www.plivo.com/legal/privacy/">Plivo</a></li>
                <li><a href="https://stripe.com/en-de/privacy">Stripe</a></li>
                <li><a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_EN">PayPal</a></li>
                <li><a href="https://www.placetel.de/rechtliches/datenschutzerklaerung">Placetel</a></li>
                <li><a href="https://sevdesk.com/privacy-policy/">sevDesk</a></li>
                <li><a href="https://www.schufa.de/en/data-privacy/">SCHUFA</a></li>
	              <li><a href="https://cloud.google.com/terms/cloud-privacy-notice">Google Cloud Services</a></li>
	              <li><a href="https://spur.us/privacy/">Spur</a></li>
	              <!--<li><a href="https://plausible.io/data-policy">Plausible</a></li>-->
              </ul>

              <h4>22. Period for which the personal data will be stored</h4>
              <p>The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.</p>
	            <p>
		            User accounts that have been inactive for over 12 months will be downgraded and have their personal data deleted. We notify the users at least 30 days prior to this action.
	            </p>
              <h4>23. Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a contract; Obligation of the data subject to provide the personal data; possible consequences of failure to provide such data </h4>
              <p>We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner).

                Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded.

                Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
              </p>


              <h4>24. Existence of automated decision-making</h4>
              <p>
                As a responsible company, we do not employ automatic decision-making or profiling of our customers.
              </p>

              <h4>25. Cloudflare</h4>
              <p>We use, for the integration of scripts and libraries on our website, a Content Delivery Network (CDN) of Cloudflare, Inc., 101 Townsend Street, San Francisco, California 94107, USA ("Cloudflare"). With a content delivery network, the content of our website is stored on the server of the service and the service distributes this content to you or your browser via a network of regional servers when the web page is accessed. The purpose of this is to be able to better fend off attacks, such as DDos attacks, on our website and, in the case of large script and data volumes, to provide you with the content of our pages quickly and in an optimized manner.</p>
              <p>Since the traffic of our website runs through servers of Cloudfare, your IP address is also transmitted to Cloudflare. Cloudflare is certified under the US Privacy Shield and thus ensures data processing in accordance with the DSGVO. We have also concluded an order processing agreement (data processing agreement) with Cloudflare, with which this company documents to us its compliance with appropriate technical and organizational measures.</p>
              <p>The legal basis for our use of Cloudflare is Art. 6 I (f) DSGVO. Our legitimate interest arises from the fact that we can use the service to take security measures against attacks on our website and large amounts of data can be delivered in an optimized manner. The encroachment on your fundamental freedoms is minor here, as Cloudflare guarantees the level of data protection according to GDPR.</p>

              <h4>26. Data transmission to SCHUFA</h4>
              <p>
                CFTools Software GmbH transmits personal data collected within the scope of this contractual relationship concerning the application, implementation and termination of this business relationship as well as data concerning non-contractual behaviour or fraudulent behaviour to SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden.
              </p>
              <p>
                The legal basis for these transfers is Article 6(1)(b) and Article 6(1)(f) of the General Data Protection Regulation (DS-GVO). Transfers on the basis of Article 6(1)(f) DS-GVO may only take place insofar as this is necessary to protect the legitimate interests of CFTools Software GmbH or third parties and does not override the interests or fundamental rights and freedoms of the data subject which require the protection of personal data. The exchange of data with SCHUFA also serves to fulfil legal obligations to carry out creditworthiness checks on customers (§ 505a and 506 of the German Civil Code).
              </p>
              <p>
                SCHUFA processes the data received and also uses it for the purpose of profiling (scoring) in order to provide its contractual partners in the European Economic Area and in Switzerland and, if applicable, in other third countries (insofar as an adequacy decision by the European Commission exists in respect of these) with information on, among other things, the assessment of the creditworthiness of natural persons.
              </p>
              <p>
                Further information on SCHUFA's activities can be found in the SCHUFA information sheet pursuant to Art. 14 of the Data Protection Regulation or online at <a href="www.schufa.de/datenschutz">www.schufa.de/datenschutz</a>.
              </p>
              <p>
                SCHUFA information sheet: <a href="https://cdn.cftools.de/schufa_de.pdf">German</a> - <a href="https://cdn.cftools.de/schufa_en.pdf">English</a>
              </p>
              <p display="none;">
                This Privacy Policy has been generated by the Privacy Policy Generator of the
                <a href="https://dg-datenschutz.de/services/external-data-protection-officer/?lang=en">
                  DGD - Your External DPO
                </a> that was developed in cooperation with  <a href="https://www.wbs-law.de/eng/">German Lawyers</a> from WILDE BEUGER SOLMECKE, Cologne.
              </p>
            </div>
          </div>
        </div>
      </section>
      <!-- Footer start -->
	    <Footer :isHomepage="false"/>
      <!-- Footer end -->
    </div>
  </div>
</template>